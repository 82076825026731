import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import
{
    formatDateAndTime,
    projectEmail, projectName,
    projectPhone, projectURL,
    serverLink,
} from "../../resources/url";
import axios from "axios";
import Banner from "../common/banner/banner";
import bg3 from "../../images/bg/09.jpg";
import DataLoader from "../common/dataLoader/dataLoader";

function ApplicationLandingPage(props)
{
    const [dateline, setDateline] = useState([]);
    const [loading, setLoading] = useState(true);

    const getDateline = async () =>
    {
        await axios
            .get(`${serverLink}application/check-dateline`)
            .then((res) =>
            {
                setDateline(res.data[0]);
                setLoading(false);
            })
            .catch((err) =>
            {
                console.log(err)
                console.log("NETWORK ERROR", err);
            });
    };

    useEffect(() =>
    {
        getDateline();
    }, []);
    return loading ? (
        <DataLoader />
    ) : (
        <>
            <Banner Image={bg3} Title={"Admission Portal"} breadcrumb={["Admission", "Admission Portal"]} height="50px" />

            <div className="mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8" >
                            <div className="">
                                <h4 className="text-info">Application Requirement</h4>

                                {/* <h4 style={{ color: "maroon" }}>How To Apply</h4> */}
                                <p className="mb-5 text-justify">
                                    The application process for entry into the programmes offered
                                    by our university are relatively similar across all
                                    departments and faculties. This seamless and simplified
                                    process is geared towards ensuring that our applicants are
                                    comfortable regardless of the level of their computing
                                    literacy.
                                </p>
                                <div className="mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <h4>Before You Apply</h4>
                                            <div className="col-md-12">
                                                <p className="fa fa-check-circle text-info" /> Find a Course
                                            </div>
                                            <div className="col-md-12">
                                                <p className="fa fa-check-circle text-info" /> Register
                                            </div>
                                            <div className="col-md-12">
                                                <p className="fa fa-check-circle text-info" /> Submit Your Application Online
                                            </div>

                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <h4>After You Applied</h4>
                                            <div className="col-md-12">
                                                <p className="fa fa-check-circle text-info" /> Login
                                            </div>
                                            <div className="col-md-12">
                                                <p className="fa fa-check-circle text-info" />Track Your Application
                                            </div>
                                            <div className="col-md-12">
                                                <p className="fa fa-check-circle text-info" />Download Your Admission Letter
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p>
                                    You can submit multiple applications to different courses, but not multiple application to the same course in the same year. Kindly note that you are to download you admission letter from the portal when you are admitted. An email notification would be sent to you about the outcome of your application via the email address you provided.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="card mb-3 mt-5">
                                <div className="card-body">
                                    <h5 className="mb-4" style={{ fontFamily: "revert-layer" }}>Apply Now</h5>
                                    {dateline.IsAlwaysOpen < 1 && (
                                        <div className="alert alert-warning">
                                            Application Dateline:{" "}
                                            {formatDateAndTime(dateline.EndDate, "date")}
                                        </div>
                                    )}
                                    <div className="">

                                        <Link to={"/admission/application/register"}>
                                            <p className="btn btn-info bnt-sm w-100 mb-5">Register</p>
                                        </Link>
                                        <Link to={"/admission/application/login"}>
                                            <p className="btn btn-info w-100">Login</p>
                                        </Link>

                                    </div>

                                </div>
                            </div>

                            <div className="card">
                                <div className="card-body">
                                    <h5>Contact Us</h5>
                                    <div className="feature-item">
                                        <a href={`tel:${projectPhone}`}>
                                            <h4 className="fa fa-phone fa-1x text-primary mb-3" />
                                            {projectPhone}
                                        </a>

                                    </div>
                                    <div className="feature-item">
                                        <a href={`mailto:${projectEmail}`}>
                                            <h4 className="fa fa-book fa-1x text-primary" /> Send us mail
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {props.courseList.length > 0 && (
                <div className="container mb-5">
                    <div className="mt-5">
                        <div className="mb-5">
                            <h3>Find Other Programs</h3>
                        </div>
                        {props.courseList.length > 0 ? (
                            <div className="row">
                                {props.courseList.map((item, key) =>
                                {
                                    return <div className="col-lg-4 col-md-6 mb-4" key={key}>
                                        <div className="course-item">
                                            <a className="badge-info" style={{ padding: "5px", borderRadius: "5px" }} href="#">{item.FacultyName.replace("College of", "")}</a>

                                            <div className="course-conten">
                                                <h5 className="mb-3">
                                                    <a href={`${projectURL}course/${item.Slug}`}>{item.CourseName}</a>
                                                </h5>
                                                <div className="course-meta">
                                                    <ul className="list-unstyled mb-0">
                                                        <li><i className="fa fa-clock pr-2" />{item.Duration} {item.DurationType}</li>
                                                        <li><i className="fa fa-user pr-2" />{item.CourseClass}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })}

                            </div>
                        ) : (
                            <h3>No Course Found</h3>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
const mapStateToProps = (state) =>
{
    return {
        courseList: state.CourseListDetails,
    };
};
export default connect(mapStateToProps, null)(ApplicationLandingPage);
