import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import AdmissionLetter from "./cosmopolitan/cu_admission";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { projectAddress, projectDomain, projectEmail, projectLogo, projectName, projectPaymentURL, projectPhone, projectViceChancellor, serverLink, shortCode } from "../../resources/url";
import { decryptData } from "../../resources/url";
import { connect } from "react-redux";
import { toast } from "react-toastify";


const AdmissionLandingPage = (props) => {
    const [data, setData] = useState({
        label_class: '',
        label_text: '',
        label_show: false,

    });
    const { slug } = useParams();
    const [dt__, setdata__] = useState([])

    const [app_det, setapp_det] = useState([]);
    const [applicant_details, setapplicant_details] = useState([]);
    const [tuition_fee, settuition_fee] = useState([]);
    const componentRef = useRef();
    const componentRef_2 = useRef();
    const [showAdmissionLetter, setShowAdmissionLetter] = useState(false);
    const [showUndertaking, setshowUndertaking] = useState(false)
    const [sendData, setSendData] = useState({})
    const [appInfo, setappInfo] = useState([]);
    const [facultyDetails, setfacultyDetails] = useState([])
    const [admissionLetter, setAdmissionLetter] = useState([]);

    const applicationID = decryptData(slug).split("=")[1];

    // const applicationID = slug.split("=")[1];

    const getData = async () => {
        getAdmissionLetter(applicationID);
    }

    const getAdmissionLetter = async (app_id) => {
        toast.info('donwloading admission letter, please wait...')
        let course_code;
        let app_info = []
        let faculty_det = []
        let desc = {}
        try {
            axios.get(`${serverLink}registration/admissions/ug/information/${app_id}`)
                .then(async (response) => {
                    course_code = response.data.course[0].CourseCode;
                    app_info = response.data;
                    setappInfo(response.data);
                })
                .then(async () => {
                    await axios.get(`${serverLink}registration/admissions/ad-faculty/${course_code}`,)
                        .then((result) => {
                            if (result.data.length > 0) {
                                faculty_det = result.data;
                                setfacultyDetails(result.data);
                            }
                        })
                }).then(async () => {
                    await axios.get(`${serverLink}registration/admissions/admission-letter/${app_id}`,)
                        .then((result) => {
                            if (result.data.length > 0) {
                                const cons = result.data[0].AdmissionCondition.split(", ");
                                desc = {
                                    level: result.data[0].AdmissionLevel,
                                    admissionSemester: result.data[0].AdmissionSchoolSemester,
                                    semester: result.data[0].AdmissionSemester,
                                    CourseName: result.data[0].CourseName,
                                    courseCode: result.data[0].AdmissionCourse,
                                    StartMonth: result.data[0]?.StartMonth,
                                    StartYear: result.data[0]?.StartYear,
                                    con1: cons[0],
                                    con2: cons[1],
                                    con3: cons[2],
                                    con4: cons[3],
                                    con5: cons[4],
                                    con6: cons[5],
                                    type: result.data[0].AdmissionType,
                                    InsertedOn: result.data[0].InsertedOn,
                                    ApplicationID: result.data[0].ApplicationID
                                }
                            }
                            const sendData = {
                                applicantInfo: app_info.applicant_data,
                                applicantCourse: app_info.course,
                                decison: desc,
                                school: {
                                    logo: projectLogo,
                                    name: projectName,
                                    address: projectAddress,
                                    email: projectEmail,
                                    phone: projectPhone,
                                    shortCode: shortCode,
                                    viceChancellor: projectViceChancellor
                                },
                                facultyDetails: faculty_det,
                                appInfo: app_info,
                            }
                            setSendData(sendData);
                            setdata__([sendData])
                            setAdmissionLetter(result.data)
                            setTimeout(() => {
                                printAdmission();
                            }, 2000);
                        })
                })
        } catch (error) {
            toast.error("Network error, please try again")
        }
    }

    useEffect(() => {
        if (slug === "") {
            window.location.href = projectDomain;
            return;
        }
        if (applicationID === "") {
            window.location.href = projectDomain;
            return;
        }

        getData();

        //window.location.reload()
    }, [])


    const getEnrolmentDocment = () => {
        window.open('EnrolmentDocument', '_blank');
    }

    const getPaymentSystemManual = () => {
        window.open('PaymentSystemManual', '_blank');
    }

    const getRegistrationForm = () => {
        window.open('RegistrationForm', '_blank');
    }

    const printAdmission = () => {
        if (window.sessionStorage.getItem("reload") === "1") {
            setShowAdmissionLetter(true);
            setTimeout(() => {
                handlePrint();
                setShowAdmissionLetter(false);
            }, 100);
        } else if (!window.sessionStorage.getItem("reload")) {
            window.sessionStorage.setItem("reload", "1")
            window.location.reload();
        } else {
            window.sessionStorage.setItem("reload", "1")
            window.location.reload();
        }
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const printUndertaking = () => {
        setshowUndertaking(true);
        setTimeout(() => {
            handleUndertakingPrint();
            setshowUndertaking(false);
        }, 100);

    }

    const handleUndertakingPrint = useReactToPrint({
        content: () => componentRef_2.current,
    });

    return dt__.length === 0 ? (<>
        <div className="card card-body alert alert-primary text-center mb-10">
            <h1>Loading!</h1>
            <h4>Please wait...</h4>
        </div></>) : (
        <div className="pcoded-inner-content" style={{ fontFamily: 'monospace', fontSize: '15px' }}>
            <div className="main-body">
                <div className="container">
                    <div className="page-body">
                        <div className="row">

                            <div className="col-sm-12">
                                <div className="row justify-content-md-center" style={{ textAlign: 'center' }}>
                                    <div className="col-md-10 mt-20">
                                        <img src={'https://cosmopolitan.edu.ng/logo.png'} width={"100px"} />
                                        <h1 className="wow fadeInUp" data-wow-delay="0s" style={{ visibility: 'visible', animationDelay: '0s', animationName: 'fadeInUp' }}>Congratulations
                                        </h1>
                                        <span className="wow fadeInUp" data-wow-delay="0.2s" style={{ visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUp', fontSize: '20px' }}>
                                            <h6>Download your admission documents</h6>
                                        </span>
                                        <Link className="btn btn-sm btn-primary" to={`/admission/application/dashboard`}>
                                            Back to Portal
                                        </Link>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="hero-image">
                                            <img className="img-fluid" src="" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="card m-20">
                                    <div className="card-body p-10 ">
                                        <div>
                                            <blockquote className="blockquote">
                                                <h4 style={{ fontSize: '15px' }} className="sub-title">Registration Guidelines</h4>
                                                <p style={{ fontSize: '15px' }}>
                                                    Follow these steps to register:
                                                </p>
                                            </blockquote>
                                        </div>

                                        <hr />
                                        <div className="row" style={{ textAlign: 'left' }}>
                                            <div className="col-md-6">
                                                <div className="col-sm-12 col-xs-12">

                                                    <ol >
                                                        <li> Download Admission Letter and other supporting documents</li>
                                                        <li>Visit our online payment system at <a style={{ color: 'blue', textDecoration: 'underline' }} href={projectPaymentURL} target={"_blank"} >{projectPaymentURL}</a></li>
                                                        <li>Login with your registered email <strong>{applicant_details[0]?.EmailAddress}</strong> and Application ID <strong>{app_det[0]?.EntryID}</strong> and make payment</li>
                                                        <li>Proceed to the registry for documents verification</li>
                                                        <li>Proceed to registry block for biometrics and ID card</li>
                                                        <li>Login to your portal and download your lecture timetable</li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-center">
                                    {
                                        data.label_show === true && <div className="col-md-12 text-">
                                            <label className={`${data.label_class} w-100`}  >{data.label_text}</label>
                                        </div>
                                    }
                                </div>


                            </div>
                            <div className="col-md-12" id="kt_content">
                                <div className="card mb-5 mb-xl-10">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12 mb-20">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h5>Admission Letter</h5>
                                                        <br />

                                                        <button className="btn btn-md btn-primary w-100" onClick={printAdmission}>Click here to download</button>
                                                        <hr />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-md-6">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h5>Undertaking Form</h5>
                                                        <br />
                                                        <button onClick={printUndertaking} className="btn btn-md btn-primary w-100">Click here to download</button>
                                                        <hr />
                                                    </div>
                                                </div>
                                            </div> */}
                                            {app_det[0]?.ApplicationType === 'ug' &&
                                                <>
                                                    {/* <div className="col-md-6">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <h5>Registration Documents</h5>
                                                                <br />
                                                                <button onClick={getEnrolmentDocment} className="btn btn-md btn-primary w-100" >Click here to download</button>
                                                                <hr />
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    {/* <div className="col-md-6">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <h5>Semester Registration Form</h5>
                                                                <br />
                                                                <button onClick={getRegistrationForm} className="btn btn-md btn-primary w-100">Click here to download</button>
                                                                <hr />
                                                            </div>
                                                        </div>
                                                    </div> */}

                                                </>
                                            }
                                        </div>
                                        <br />
                                        {/* <div className="col-sm-12 mt-5">
                                            <div className="card">
                                                <div className="card-body   ">
                                                    <h5>Payment Portal Manual</h5>
                                                    <br />
                                                    <button onClick={getPaymentSystemManual} className="btn btn-md btn-primary w-100">Click here to download</button>
                                                    <hr />
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>

                                </div>
                            </div>
                            {
                                showAdmissionLetter && <AdmissionLetter componentRef={componentRef} data={dt__} />

                            }
                            {/* {
                                showUndertaking && <AdmissionUnderTaking componentRef={componentRef_2} data={dt__} />
                            } */}
                        </div>
                    </div>
                </div>
            </div>


            <div className="d-flex text-center">
                @2022 Baze University
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        userData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(AdmissionLandingPage);
