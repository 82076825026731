import axios from "axios";
import swal from "sweetalert";
const CryptoJS = require("crypto-js");

const status = "Live";

export const serverLink =
  status === "Dev"
    ? "http://localhost:4480/"
    // : "https://smartsourcingapi.com:5001/"; //lux mundi
    : "https://gargatechapi.com.ng:5008/";

// export const paystackAPIKey = "";
// export const applicationFee = 2000;
// export const projectName = "Lux Mundi University";
// export const projectCode = "LUX_MUNDI_UNIVERSITY";
// export const serverStatus = status;
// export const projectURL = "https://luxmundi.edu.ng/";
// export const projectPhone = "+234906203771 \n +2349062037772";
// export const projectEmail = "registrar@luxmundi.edu.ng \n doa@luxmundi.edu.ng";
// export const projectAddress = "Umuahia, Abia State, Nigeria";
// export const projectStudentURL = "https://student.luxmundi.edu.ng/";
// export const projectAdmissionURL = "https://admission.luxmundi.edu.ng/";
// export const projectStaffURL = "https://staff.luxmundi.edu.ng/";
// export const projectPaymentURL = "https://payment.luxmundi.edu.ng"
// export const projectLogo = "https://admission.luxmundi.edu.ng/logo.png";
// export const projectViceChancellor = ""
// export const shortCode = "LMU"
//
// // SOCIAL MEDIA
// export const projectFacebook = "https://www.facebook.com/";
// export const projectTwitter = "https://www.twitter.com/";
// export const projectInstagram = "https://instagram.com/?lang=en";
// export const projectLinkedIn = "https://linkedin.com/?lang=en";
// export const projectYoutube = "https://www.youtube.com/u";
// //
// export const projectVision = "The vision of the proposed Lux Mundi University, Umuahia is to be a world-class institution that offers students the golden key to a bright future through high quality integrated education"


export const paystackAPIKey = "";
export const applicationFee = 2000;
export const projectName = "Cosmopolitan University";
export const projectCode = "COSMOPOLITAN_UNIVERSITY";
export const serverStatus = status;
export const projectURL = "https://cosmopolitan.edu.ng/";
export const projectPhone = "+234 805 208 0828";
export const projectEmail = "admission@cosmopolitan.edu.ng";
export const projectAddress = "Abuja, Nigeria";
export const projectStudentURL = "https://student.cosmopolitan.edu.ng/";
export const projectStaffURL = "https://staff.cosmopolitan.edu.ng/";
export const projectPaymentURL = "https://payment.cosmopolitan.edu.ng"
export const projectAdmissionURL = "https://admission.cosmopolitan.edu.ng/";
export const projectLogo = "https://cosmopolitan.edu.ng/logo.png";
export const projectViceChancellor = "Prof Carl Adams"
export const shortCode = "CU"

//SOCIAL MEDIA
export const projectFacebook = "https://www.facebook.com/";
export const projectTwitter = "https://www.twitter.com/";
export const projectInstagram = "https://instagram.com/?lang=en";
export const projectLinkedIn = "https://linkedin.com/?lang=en";
export const projectYoutube = "https://www.youtube.com/u";

export const projectVision = "To be a student-centred university offering applied and innovative experimental programs to prepare graduates to make a positive impact in their respective workplaces, environment, and communities"

export function showAlert(title, msg, type)
{
  return swal({
    title: title,
    text: msg,
    icon: type,
    button: "OK",
  })
}


export function encryptData(string, val = false)
{
  let secret_key = val === false ? "SayyoFilms" : projectCode;
  let secret_iv = val === false ? "FilmsInternational" : projectCode;
  // hash
  let kee = CryptoJS.SHA256(secret_key);
  let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);

  kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
  ivv = CryptoJS.enc.Utf8.parse(ivv);

  let decrypted = CryptoJS.AES.encrypt(string, kee, { iv: ivv });

  let result = decrypted.toString();
  return btoa(result);
}

let domain = "";
switch (projectCode)
{
  case "OLIVIA_UNIVERSITY":
    domain = "@oliviauniversity.com";
    break;
  case "BABA_AHMED_UNIVERSITY":
    domain = "@babaahmeduniversity.edu.ng";
    break;
  case "ALANSAR_UNIVERSITY":
    domain = "@aum.edu.ng";
    break;
  case "LUX_MUNDI_UNIVERSITY":
    domain = "@luxmundi.edu.ng";
    break;
  case "COSMOPOLITAN_UNIVERSITY":
    domain = "@cosmopolitan.edu.ng";
    break;
  default:
    domain = "";
}
export const projectDomain = domain;

export function decryptData(string, val = false)
{
  let secret_key = val === false ? "SayyoFilms" : projectCode;
  let secret_iv = val === false ? "FilmsInternational" : projectCode;
  // hash
  let kee = CryptoJS.SHA256(secret_key);
  let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);

  kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
  ivv = CryptoJS.enc.Utf8.parse(ivv);

  var decrypted = CryptoJS.AES.decrypt(atob(string), kee, { iv: ivv });

  return decrypted.toString(CryptoJS.enc.Utf8);
}

export const formatDate = (date) =>
{
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const formatDateAndTime = (date, option) =>
{
  if (date !== null)
  {
    const user_date = new Date(date);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthNamesShort = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day =
      user_date.getDate() < 10
        ? "0" + user_date.getDate()
        : user_date.getDate();
    const hour =
      user_date.getHours() < 10
        ? "0" + user_date.getHours()
        : user_date.getHours();
    const min =
      user_date.getMinutes() < 10
        ? "0" + user_date.getMinutes()
        : user_date.getMinutes();
    const sec =
      user_date.getSeconds() < 10
        ? "0" + user_date.getSeconds()
        : user_date.getSeconds();

    let date_string = "";
    if (option === "date_and_time")
      date_string = `${day}-${monthNames[user_date.getMonth()]
        }-${user_date.getFullYear()} : ${hour}:${min}:${sec}`;
    else if (option === "date")
      date_string = `${day}-${monthNames[user_date.getMonth()]
        }-${user_date.getFullYear()}`;
    else if (option === "day") date_string = day;
    else if (option === "full_month")
      date_string = monthNames[user_date.getMonth()];
    else if (option === "short_month")
      date_string = monthNamesShort[user_date.getMonth()];
    else if (option === "year_only") date_string = user_date.getFullYear();

    return date_string;
  } else
  {
    return "--";
  }
};

export const sendEmail = (email, subject, title, name, body, signature, app_id = '') =>
{
  const sendEmail = {
    logo: projectLogo,
    from: projectEmail,
    to: email,
    subject: subject,
    title: title,
    name: name,
    body: body,
    signature: signature,
    app_id: app_id
  };
  axios.post(`${serverLink}send_email/send`, sendEmail).then((r) =>
  {
    //console.log("email sent");
  });

  return "sent";
};

export const currencyConverter = (amount) =>
{
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'NGN',
  });
  return formatter.format(amount)
}

export const EmailTemplates = (type, details) =>
{
  if (type === '1')
  {
    const message = {
      subject: 'password recovery',
      title: '',
      body: `<div style="line-height: 1.6">Your job application portal password is ${details.Password} <br/><br/>For further enquiries, please contact <b/>${projectEmail}<br/>${projectPhone}</div>`
    }
    return message;
  }
  else if (type === '2')
  {
    const message = {
      subject: 'Admission',
      title: 'Password Recovery',
      body: `<div style="line-height: 1.6">
      You have requested to reset your password, kinndly follow this <a href='${projectAdmissionURL}admission/application/recover-password/${details}'>link</a> to reset your password or copy and paste <code>${projectAdmissionURL}admission/application/recover-password/${details}</code> in your browser .
       <br/><br/>For further enquiries, please contact <b/>${projectEmail}<br/>${projectPhone}
      </div>`
    }
    return message;
  }
};
